.footer {
    padding-top: 15px;
    font-size: 15px;
    color: white;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #14162b;
}

.footwords {
    font-size: 15px;
    color: white;
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
}