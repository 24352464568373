.slider-container {
    position: relative;
}

.infoImage {
    position: relative;
    width: 100%;
    min-height: 570px;
    /* Default min-height for mobile */
    display: block;
    object-fit: cover;
    filter: brightness(0.4);
}

/* Media query for screens wider than 768px (for example) */
@media (min-width: 768px) {
    .infoImage {
        max-height: 560px;
        /* New min-height for desktop */
    }
}




.button-container {
    position: absolute;
    bottom: 50px;
    padding-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

@media (max-width: 767px) {
    .button-container {
        position: absolute;
        bottom: 40px;
        padding-bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}


.prev,
.next {
    font-size: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    color: white;
    outline: none;
    padding: 5px;
}

.prev {
    margin-right: 300px;
}

@media (max-width: 767px) {
    .prev {
        margin-right: 100px;
    }
}



.info-words {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 30px;
    z-index: 1;
    width: 760px;


}


@media (max-width: 767px) {
    .infoImage {
        height: 400px;
    }

    .info-words {
        font-size: 24px;
        padding-bottom: 15px;
        margin-top: -30px;
        width: 360px;
    }
}

.slide {
    position: relative;
}

.info-button {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    /* other styles for the button */
}

@media (max-width: 767px) {
    .info-button {
        position: absolute;
        bottom: 140px;
        left: 50%;
        transform: translateX(-50%);
        /* other styles for the button */
    }
}