.logo2 {
  height: 35px;
  width: auto;
  padding: 4px;
  padding-left: 13px;
  margin-top: 20px;
  margin-bottom: 10px;
}


.logo3 {
  height: 49px;
  width: auto;
  padding: 4px;
  padding-left: 13px;
  margin-top: 20px;
  margin-bottom: 10px;
}



.navbar {
  color: white;
  padding: 4px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}


.navbar-toggler {
  border-color: transparent;
}