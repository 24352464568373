.testimonial-page {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
    /* Adjusted to remove margin at the top and bottom */
    width: 100vw;
    /* Ensure it spans the full viewport width */
    min-height: 100vh;
    /* Ensure it spans the full viewport height */
    overflow: hidden;
    /* Added to prevent overflow of the background image */
}

.testimonial {
    padding: 20px;
    background: rgba(249, 249, 249, 0.9);
    /* Make the testimonial background slightly transparent */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.testimonial-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.testimonial-name {
    font-size: 14px;
    color: #666;
    text-align: right;
    margin-top: auto;
}