.product-display-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Equal width columns for details and image */
    gap: 20px;
    /* Space between grid items */
    margin: 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image img {
    width: 100%;
    /* Make the image responsive */
    height: auto;
    border-radius: 10px;
}

.product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-title {
    color: #333;
    font-size: 24px;
}

.product-description {
    font-size: 16px;
    color: #666;
}

.product-price {
    font-size: 20px;
    font-weight: bold;
    color: #388e3c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .product-display-grid {
        grid-template-columns: 1fr;
        /* Stack items vertically on smaller screens */
    }
}