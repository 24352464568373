/* contactForm.css */

.formC {

    text-align: center;
    width: 80vw;
    max-width: 1100px;
    /* Adjust the max-width as needed */
    margin: auto;
    /* This centers the form in its parent */
    margin-top: 35px;
}

@media (min-width: 768px) {
    .formC {
        width: 80vw;
    }
}

/* Apply a consistent style to all input fields */
.formC input,
.formC textarea {
    display: block;
    /* Make each input take the full width */
    width: 100%;
    /* Set the width to 100% of the formC */
    padding: 10px;
    /* Add some padding inside the inputs */
    margin-bottom: 20px;
    /* Add some space between each field */
    background-color: transparent;
    color: white;
    border: 1px solid white;
    /* You can adjust the border style as needed */
    border-radius: 4px;
    /* Optional: rounds the corners */
}

.formC .submit {
    width: 100%;
    /* You might want the button width to be auto or 100% */
    padding: 10px 20px;
    /* Adjust padding as needed */
    border: 1px solid white;
    /* Optional: if you want a border on the button */
    background-color: transparent;
    color: white;
    cursor: pointer;
    /* Changes the cursor to indicate it's clickable */
    margin-bottom: 20px;
    /* Adds space below the button */
    transition: background-color 0.3s, color 0.3s;
    /* Smooth transition for hover effects */
}

.formC .submit:hover {
    background-color: white;
    /* Change color on hover */
    color: black;
    /* Text color on hover */
}

/* Remove the default browser outline on focus and replace with a custom style */
.formC input:focus,
.formC textarea:focus {
    outline: none;
    border-color: #007bff;
    /* Change the border color on focus */
}

.cForm {
    text-align: center;
    color: white;
    margin-bottom: 38px;
}

.cWords {
    font-size: 35px;
}